<template>
  <div class="cor-padrao">
    <div class="container-fluid">
      <div class="title">
        <h6 class="text-white">Ações rápidas</h6>
      </div>
    </div>
    <carousel :items-to-show="3">
      <slide v-for="(slide, index) in slideData" :key="index">
        <ItemAcessoRapido :cardData="slide" />
      </slide>
    </carousel>
  </div>
</template>
  
<script>
import {  mapGetters } from "vuex";
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide } from 'vue3-carousel'
import ItemAcessoRapido from './ItemAcessoRapido.vue';
import apiMethods from '../views/service/homeService';

export default {
  name: 'App',
  components: {
    Carousel,
    Slide,
    ItemAcessoRapido
  },
  computed: {
    ...mapGetters({
        usuario: "usuario"
    })
  },
  data() {
    return {
      clube: '',
      indent: '',
      slideData: []
    }
  },

  created() {
   this.acessoRapido()
  },

  methods: {
    async acessoRapido() {
    this.cargo = this.usuario.funcao 
  
      let montaAcesso = await apiMethods.getAcessoRapido(this.cargo)

      // consle.log("acesso", montaAcesso);


      this.slideData = Object.keys(montaAcesso).map(key => {
    const item = montaAcesso[key];
    return {
        title: item.title,
        subtitle: item.subtitle,
        link: item.link
    };
});

    }
  },
}
</script>
<style scoped lang="scss">
.cor-padrao {
  background-color: #0a0a0a;
}

.container-fluid {
  display: flex;
  align-items: flex-start;
  /* Alinhar conteúdo no início do eixo vertical */
  margin-left: -48px;
  margin-top: 7px;
}

.title {
  width: 200px;
  /* Largura do título */
  margin-right: 20px;
  /* Espaçamento entre o título e os cards */
}

.title h2 {
  margin-bottom: 10px;
  /* Espaçamento abaixo do título */
}

.content {
  flex: 1;
  /* Ocupa todo o espaço restante */
}
</style>