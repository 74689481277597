<template>
  <div class="background">
    <div class="perfil-wrapper">
      <a class="footer-icon" @click="openModal(cadastro)">
        <i class="fa fa-user-circle fa-2x" aria-hidden="true"></i> <!-- Ícone de Perfil -->
        <span class="icon-font"></span>

      </a>
      &nbsp;
      <a v-if="isNotIconUser" class="footer-icon" @click="openModalUsuario()">
        <i class="fa fa-user-plus fa-1x" aria-hidden="true"></i> <!-- Ícone de criar Perfil -->
        <span class="icon-font"></span>

      </a>
    </div>
    <br>
    <br>
    <div class="masthead text-white text-center">
      <div class="container d-flex align-items-center flex-column">
       
        <!-- Masthead Avatar Image-->
        <div v-if="isAvatar">
          <div class="masthead-avatar mb-3">
           <div class="avatar-circle">
              <img class="avatar-image" :src="avatarURL" alt="Avatar" />
            </div>
          </div>
        </div>
        <div v-else>
          <div class="masthead-avatar mb-3">
            <div class="avatar-circle">
              <img class="avatar-image" src="https://clubesavt-app.com/img/avatar.jpg" alt="Avatar2" />
            </div>
          </div>
        </div>

        <!-- Masthead Heading-->
        <h1 class="masthead-heading text-uppercase mb-0">{{ clube }}</h1>
      </div>
      <br>
    </div>
  </div>
  <modal ref="modal"></modal>
  <modalAdcionaUser ref="modalAdcionaUser"></modalAdcionaUser>
</template>

<script>
import { mapGetters } from 'vuex';
import apiMethods from '../views/service/homeService';
import modal from '../components/modal.vue';
import modalAdcionaUser from '../components/ModalAdcionaUser.vue';
export default {
  name: 'HelloWorld',
  components: {
    modal,
    modalAdcionaUser
  },
  computed: {
    ...mapGetters({
      usuario: "usuario"
    })
  },
  data() {
    return {
      clube: null,
      avatarURL: null,
      cadastro: [],
      imageName: null,
      im: null,
      isNotIconUser: false,
      isAvatar: false
    };
  },
  async created() {
    ///  BUSCA PERFIL DO UUSUARIOS //////////////////////////////////
    const chave = this.usuario.chave;
    let usuarioPerfil = await apiMethods.getUsers(chave);
    usuarioPerfil.map((us) => {
      this.clube = us.funcao;
      this.cadastro = us;
    });
    //// RETORNA IMAGEM DO PERFIL //////////////////////////////////
    let avatar = await apiMethods.avatar(chave);
    this.imageName = avatar.filePath;
    this.avatarURL = `https://apiventureirosonline.com/uploads/${this.imageName}`;

    console.log("avatar", this.imageName);
    if (this.imageName !== 'undefined') {
      this.isAvatar =  true
    }
    

    ////// ACESSO PERFIL ICON CRIAR USUARIO NO SISTEMA SOMENTE COORDENADOR E SECRETARIO /////////

    const perfilFuncao = this.usuario.funcao.toLowerCase()

    if (perfilFuncao === 'coordenador' || perfilFuncao === 'secretario') {
      this.isNotIconUser = true
    }




  },
  methods: {
    async openModal(dados) {
      if (this.$refs.modal && typeof this.$refs.modal.show === 'function') {
        this.$refs.modal.show(dados);
      } else {
        console.error('Modal component is not properly referenced or method show does not exist.');
      }
    },
    async openModalUsuario() {
      if (this.$refs.modalAdcionaUser && typeof this.$refs.modalAdcionaUser.show === 'function') {
        this.$refs.modalAdcionaUser.show();
      } else {
        console.error('Modal component is not properly referenced or method show does not exist.');
      }
    }
  }
};
</script>


<style scoped>
.background {
  background-color: #800000;
  /* Cor de fundo */
  /* Ou use uma imagem de fundo */
  /* background-image: url('../assets/background.jpg'); */
  /* background-size: cover; */
  /* background-position: center center; */
}

.masthead {
  padding-top: calc(1rem + 4px);
}

.masthead .masthead-heading {
  font-size: 4rem;
  line-height: 3.5rem;
}

.masthead .masthead-heading {
  font-size: 1.25rem;
  line-height: 1rem;
}

.masthead .masthead-subheading {
  font-size: 1.25rem;
}

.masthead-avatar {
  width: 10rem;
}

.avatar-circle {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  /* Isso faz a imagem ser redonda */
  overflow: hidden;
  /* Garante que a imagem não ultrapasse os limites do círculo */
  border: 2px solid white;
  /* Adiciona uma borda branca ao redor do círculo */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  /* Adiciona uma sombra à moldura */
}

.avatar-image {
  width: 100%;
  /* Faz a imagem preencher o círculo */
  height: auto;
}

a {
  color: rgb(240 242 243);
  text-decoration: underline;
}

.perfil-wrapper {
  position: absolute;
  /* Torna o ícone fixo no canto da tela */
  top: 20px;
  /* Ajuste a distância do topo conforme necessário */
  right: 20px;
  /* Ajuste a distância da direita conforme necessário */
  z-index: 1000;
  /* Garante que o ícone fique acima de outros elementos */
}

.perfil {
  color: #fff;
  /* Cor do texto dos ícones */
  text-decoration: none;
  /* Remover sublinhado */
  font-size: 1.1em;
  /* Tamanho dos ícones */
}

@media (min-width: 992px) {
  .masthead {
    padding-top: calc(6rem + 104px);
    padding-bottom: 6rem;
  }

  .masthead .masthead-subheading {
    font-size: 1.5rem;
  }
}
</style>