<template>
  <div>
    <div v-if="isVisible" class="modal" @animationend="handleAnimationEnd">
      <div class="modal-content background" :class="{ 'slide-out': isClosing }">
        <span @click="hide" class="close"><i class="fas fa-arrow-left"></i></span>
        <slot>
          <br>
          <br>
          <div class="card">
            <div v-if="editarImagem" class="masthead text-white text-center">
              <div class="container d-flex align-items-center flex-column">
                <!-- Masthead Avatar Image -->
                <div v-if="isAvatar">
                  <div class="masthead-avatar mb-3">
                    <div class="avatar-circle">
                      <img class="avatar-image" :src="avatarURL" alt="Avatar" />
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="masthead-avatar mb-3">
                    <div class="avatar-circle">
                      <img class="avatar-image" src="https://clubesavt-app.com/img/avatar.jpg" alt="Avatar" />
                    </div>
                  </div>
                </div>

                <!-- Masthead Heading -->
              </div>
            </div>

            <div class="card-body">
              <div style="position: relative;" id="divcropper1">
                <h6 class="title-cropper">{{ title }}</h6>
                <div v-if="!srcImage" class="div-father-btn-toggle-camera">

                  <div v-if="toggle" class="mf__div-btn-flex-space-between">
                    <button class="mf__btn--confirmar" v-if="nameNavigator === 'Windows OS'"
                      @click.prevent="loadCamera">Foto</button>&nbsp;&nbsp;&nbsp;
                    <label v-else :for="`capture-upload-${title}`" class="custom-btn-upload">Tirar
                      Foto</label>&nbsp;&nbsp;&nbsp;
                    <input style="display: none;" @change="setImage" :id="`capture-upload-${title}`" type="file"
                      accept="image/*" capture="camera">
                    <label :for="`file-upload-${title}`" class="custom-btn-upload">Upload de Imagem</label>
                    <input style="display: none;" @change="setImage" :id="`file-upload-${title}`" type="file"
                      accept="image/*">
                  </div>
                  <div id="div_area" class="area" style="display: none;">
                    <video autoplay="true" id="webCamera"></video>
                    <button class="custom-btn-upload" type="button" @click.prevent="takeSnapShot">Tirar foto</button>
                  </div>
                </div>
                <div v-else class="img-document" id="divcropper2">
                  <div v-if="loading">
                    <div class="base_spinner" style="display: flex; position: relative;">
                      <svg class="spinner" width="50px" height="35px" viewBox="0 0 66 66"
                        xmlns="http://www.w3.org/2000/svg">
                        <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
                        </circle>
                      </svg>
                    </div>
                  </div>
                  <div v-else id="divcropper3">
                    <div v-if="redondlyMode" id="divcropper4">
                      <Cropper id="cropper1" ref="cropper" v-if="!cropedImage" :src="srcImage"
                        :stencil-props="{ previewClass: 'preview' }" stencil-component="circle-stencil" />
                    </div>
                    <div v-else-if="!redondlyMode" id="divcropper5">
                      <Cropper id="cropper2" ref="cropper" v-if="!cropedImage" :src="srcImage" />
                    </div>
                    <div v-if="!cropedImage" class="container">
                      <br>
                      <button @click.prevent="cancelImage" class="button">Cancelar</button>&nbsp;&nbsp;&nbsp;
                      <button @click.prevent="cropAndUploadImage" class="">Ok</button>
                    </div>
                    <div v-else-if="cropedImage" id="div_area">
                      <br>
                      <img class="webCamera" :src="cropedImage">
                      <button class="button" @click.prevent="changeImg">Escolher outra imagem</button>
                      &nbsp;&nbsp;&nbsp;
                      <button @click="fechar()" class="">Fechar</button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="edit" @click.prevent="toggleOptions">
                <i class="fas fa-edit"></i>
              </div>
              <h5 class="card-title">{{ usuario.nome }}</h5>
            </div>
            <ul class="list-group list-group-flush">
              <li class="list-group-item">{{ usuario.email }}</li>
              <li class="list-group-item">{{ usuario.clube }}</li>
            </ul>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>
<script>
import apiMethods from '../views/service/homeService';
import photo from '@/assets/logo-180x180.png'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
export default {
  name: 'ModalPerfil',
  components: {
    Cropper
  },
  data() {
    return {
      isVisible: false,
      isClosing: false,
      srcImage: null,
      cropedImage: null,
      toggle: false,
      loading: false,
      nameNavigator: '',
      title: 'Foto',
      video: null,
      tag: 'avatar',
      editarImagem: true,
      usuario: {
        nome: "",
        email: "",
        clube: "",
        chave: ""
      },
      avatarURL: "",
      isAvatar: false
    };
  },
  created() {
    this.navigator()
    if (this.picture) {
      this.cropedImage = this.picture
      this.srcImage = this.picture
    }
  },
  methods: {
    async show(dados) {
      this.isClosing = false;
      this.isVisible = true;
      // consle.log("modal dados", dados);
      this.usuario = {
        nome: dados.nome,
        email: dados.email,
        clube: dados.clube,
        chave: dados.chave
      }
      let avatar = await apiMethods.avatar(this.usuario.chave);
      this.imageName = avatar.filePath;
      // consle.log("avatar: ", avatar.filePath);
      if (avatar) {
        this.avatarURL = `https://apiventureirosonline.com/uploads/${this.imageName}`;
      } else {
        this.avatarURL = photo
      }

      if (this.imageName !== 'undefined') {
        this.isAvatar = true
      }

    },
    hide() {
      this.isClosing = true;
    },
    fechar() {
      location.reload();
    },
    handleAnimationEnd() {
      if (this.isClosing) {
        this.isVisible = false;
        this.isClosing = false; // Reset isClosing
      }
    },
    editImage() {
      // Adicione a lógica para editar a imagem aqui
      // consle.log("Edit image clicked");
    },
    closeVideo() {
      // consle.log("close vidoe");
      if (this.video) {
        var mediaStream = this.video.srcObject
        var tracks = mediaStream.getTracks()
        tracks[0].stop()
      }
    },
    dataURItoBlob(dataURI) {
      // convert base64/URLEncoded data component to raw binary data held in a string
      var byteString;
      if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
      else
        byteString = unescape(dataURI.split(',')[1]);
      // separate out the mime component
      var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
      // write the bytes of the string to a typed array
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ia], { type: mimeString })
    },
    takeSnapShot() {
      this.video = document.querySelector("#webCamera")
      //Criando um canvas que vai guardar a imagem temporariamente
      var canvas = document.createElement('canvas')
      canvas.width = this.video.videoWidth
      canvas.height = this.video.videoHeight
      var ctx = canvas.getContext('2d')
      //Desenhando e convertendo as dimensões
      ctx.drawImage(this.video, 0, 0, canvas.width, canvas.height)
      //Criando o JPG
      //O resultado é um BASE64 de uma imagem.
      var dataURI = canvas.toDataURL('image/jpeg')
      //Transformar Base em file
      var file = this.dataURItoBlob(dataURI)
      //criar objeto para setImage
      var e = { target: { files: [file] } }
      this.setImage(e)
      this.closeVideo()
    },
    loadCamera() {
      //Captura elemento de vídeo
      document.getElementById('div_area').style.display = 'block'
      var video = document.querySelector("#webCamera")
      //As opções abaixo são necessárias para o funcionamento correto no iOS
      video.setAttribute('autoplay', '')
      video.setAttribute('muted', '')
      video.setAttribute('playsinline', '')
      //--
      //Verifica se o navegador pode capturar mídia
      if (navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia({ audio: false, video: { facingMode: 'user' } })
          .then(function (stream) {
            //Definir o elemento vídeo a carregar o capturado pela webcam
            video.srcObject = stream
          })
          .catch(function (error) {
            alert("Oooopps... Falhou :'(", error)
          })
      }
    },
    navigator() {
      if (navigator.appVersion.indexOf("Win") != -1) {
        this.nameNavigator = "Windows OS";
      } else {
        this.nameNavigator = "Outro OS";
      }
    },
    toggleOptions() {
      this.editarImagem = false;
      this.toggle = !this.toggle
    },
    uploadImage(image) {
      apiMethods.uploadImage(this.usuario.chave, image, this.tag, this.usuario.nome).then((url) => {
        this.nameImagem = url.filePath;
        this.filename = image;
        this.cropedImage = `https://apiventureirosonline.com/uploads/${url.filePath}`
        this.loading = false
        this.$emit('upload-foto', url)
      })
    },
    changeImg() {
      apiMethods.deleteImage(this.chave, this.filename).then(() => {
        this.cancelImage();
        this.$emit('upload-foto', "")
      }).catch((err) => {
        console.log(err)
      })
    },
    cancelImage() {
      this.srcImage = null
      this.cropedImage = null
    },
    cropAndUploadImage() {
      const { canvas } = this.$refs.cropper.getResult();
      this.loading = true
      canvas.toBlob(blob => {
        this.uploadImage(blob)
      }, 'image/jpeg');
    },
    setImage(e) {
      const file = e.target.files[0];
      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file');
        return;
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.srcImage = event.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        alert('Sorry, FileReader API not supported');
      }
    },
  }
};
</script>
<style scoped>
.background {
  background-color: #800000;
}

.masthead {
  padding-top: calc(1rem + 4px);
}

.avatar-circle {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  position: relative;
}

.avatar-image {
  width: 100%;
  height: auto;
}

.edit-icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
}

.edit-icon:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #020202;
  padding: 20px;
  border: none;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  animation: slide-in 0.5s forwards;
}

@keyframes slide-in {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

.slide-out {
  animation: slide-out 0.5s forwards;
}

@keyframes slide-out {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(100%);
  }
}

.close {
  color: #fafafa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  position: fixed;
  z-index: 1;
  left: 0;
}

.form-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #800000;
  border-radius: 5px;
  background-color: #800000;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: white;
  text-align: left;
}

select,
input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

button {
  padding: 10px 15px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.white-icon {
  color: #ffffff;
}

.div-btn-toggle-camera {
  text-align: center;
}

.div-btn-flex-space-between {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.custom-btn-upload {
  cursor: pointer;
  background-color: #007bff;
  color: white;
  padding: 10px;
  border-radius: 5px;
  display: inline-block;
  text-align: center;
  margin-left: 5px;
}

.custom-btn-upload:hover {
  background-color: #0056b3;
}

.area {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
  background-color: #800000;
  padding: 20px;
  border-radius: 5px;
}

#webCamera {
  max-width: 100%;
  max-height: 70vh;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.webCamera {
  max-width: 100%;
  max-height: 40vh;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.custom-btn-upload {
  cursor: pointer;
  background-color: #007bff;
  color: white;
  padding: 10px;
  border-radius: 5px;
  display: inline-block;
  text-align: center;
  margin-top: 10px;
}

.custom-btn-upload:hover {
  background-color: #0056b3;
}

.base_spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.spinner {
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.path {
  stroke: #007bff;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
</style>
