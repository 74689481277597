const axios = require('axios');


// Objeto contendo os métodos para fazer requisições
const apiMethods = {
    async getUsers(chave) {
        try {
            let response = []
            response = await axios.get(`https://apiventureirosonline.com:3000/usuarios/${chave}`);
            // // consle.log("response.data",response.data);
            return response.data;
        } catch (error) {
            console.error('Erro ao obter usuarios:', error);
            
        }
    },

    async getAcessoRapido(cargo) {
        try {
            let response = []
            response = await axios.get(`https://apiventureirosonline.com:3000/acessorapido/${cargo}`);
            // // consle.log("response.data",response.data);
            return response.data;
        } catch (error) {
            console.error('Erro ao obter usuarios:', error);
            
        }
    },

    async login(email, password) {
        const user = {
            email: email,
            password: password
        }
        try {
            const response = await axios.post('https://apiventureirosonline.com:3000/login', user);
            if (response.data.token) {
                return response.data;
            } else {
                 return response.data.status;
            }
        } catch (error) {
            console.error('Erro ao login:', error);
            
        }
    },
    async roudChave() {
        try {

            let response = []
            response = await axios.get(`https://apiventureirosonline.com:3000/chave`);
            // consle.log(response);
            return response.data;
        } catch (error) {
             console.log('Erro ao obter roudChave:', error);
            
        }
    },
    async avatar(chave) {
        try {

            let response = []
            response = await axios.get(`https://apiventureirosonline.com:3000/avatar/${chave}`);
            // consle.log(response);
            return response.data;
        } catch (error) {
             console.log('Erro ao obter usuários:', error);
          
        }
    },

    async criaAgenda(dados) {
        try {

            let response = []
            response = await axios.post('https://apiventureirosonline.com:3000/evento/events', dados);
            // consle.log(response);
            return response.data;
        } catch (error) {
            console.log('Erro ao obter criaAgenda:', error);
          
        }
    },

    async editarAgenda(dados){
        try {

            let response = []
            response = await axios.post('https://apiventureirosonline.com:3000/evento/editarevents', dados);
            // consle.log(response);
            return response.data;
        } catch (error) {
            console.log('Erro ao obter editarAgenda:', error);
          
        }
    },

    async cancelaAgenda(id){
        try {

            let response = []
            response = await axios.get(`https://apiventureirosonline.com:3000/evento/cancelaAgenda/${id}`);
            // consle.log(response);
            return response.data;
        } catch (error) {
            console.log('Erro ao obter cancelaAgenda:', error);
          
        }
    },

    async visitas(dados) {
        try {

            let response = []
            response = await axios.post('https://apiventureirosonline.com:3000/visitasclube/visitasclube', dados);
            // consle.log(response);
            return response.data;
        } catch (error) {
            console.error('Erro ao obter usuários:', error);
           
        }
    },

    async updateVisitas(dados) {
        try {

            let response = []
            response = await axios.post('https://apiventureirosonline.com:3000/visitasclube/updatevisitasclube', dados);
            // consle.log(response);
            return response.data;
        } catch (error) {
            console.error('Erro ao obter usuários:', error);
           
        }
    } ,

    async clubeAtividades(dados) {
        try {

            let response = []
            response = await axios.post('https://apiventureirosonline.com:3000/atividadesclube/atividadesClube', dados);
            // consle.log(response);
            return response.data;
        } catch (error) {
            // consle.log('Erro ao obter usuários:', error);
            
        }
    },


    async obterVisitas() {
        try {

            let response = []
            response = await axios.get('https://apiventureirosonline.com:3000/visitasclube/obterVisitas');
            // consle.log(response);
            return response.data;
        } catch (error) {
            // consle.log('Erro ao obter obterVisitas:', error);
          
        }
    },
    async obterAtividadesclube(chave) {
        try {

            let response = []
            response = await axios.get(`https://apiventureirosonline.com:3000/atividadesclube/obterAtividadesclube/${chave}`);
            // consle.log(response);
            return response.data;
        } catch (error) {
            // consle.log('Erro ao obter obterVisitas:', error);
            
        }
    },
    async uploadImage(chave, cropData, tag, fileName) {
        // consle.log("imagem", chave, cropData, fileName);
        try {
            // Criar um objeto FormData para enviar os dados da imagem
            let formData = new FormData();
            formData.append('chave', chave);
            formData.append('file', cropData, fileName); // Isso adiciona o arquivo
            formData.append('fileName', fileName); // Isso adiciona o nome do arquivo
            formData.append('tag', tag); // Isso adiciona o nome do arquivo

            const response = await axios.post('https://apiventureirosonline.com:3000/imagens', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao enviar imagem:', error);
            
        }
    },
    async uploadImageVisita(chave, tag, cropData, fileName) {
        // consle.log("imagem", chave, cropData, fileName);

        try {
            // Criar um objeto FormData para enviar os dados da imagem
            let formData = new FormData();
            formData.append('chave', chave);
            formData.append('tag', tag);
            formData.append('file', cropData, fileName); // Isso adiciona o arquivo
            formData.append('fileName', fileName); // Isso adiciona o nome do arquivo

            const response = await axios.post('https://apiventureirosonline.com:3000/imagensvisitas/imagensvisitas', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao enviar imagem:', error);
            
        }
    },

    async uploadImageAtividadesClube(chave, tag, cropData, fileName) {
        // consle.log("imagem", chave, cropData, fileName);

        try {
            // Criar um objeto FormData para enviar os dados da imagem
            let formData = new FormData();
            formData.append('chave', chave);
            formData.append('tag', tag);
            formData.append('file', cropData, fileName); // Isso adiciona o arquivo
            formData.append('fileName', fileName); // Isso adiciona o nome do arquivo

            const response = await axios.post('https://apiventureirosonline.com:3000/atividadesclube/imagensatividadesclube', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return response.data;
        } catch (error) {
            // consle.log('Erro ao enviar imagem:', error);
            
        }
    },
    

    async deleteImageVisitas(chave, tag, cropData, fileName) {
        // consle.log("imagem", chave, cropData, fileName);
        try {
            // Criar um objeto FormData para enviar os dados da imagem
            let formData = new FormData();
            formData.append('chave', chave);
            formData.append('tag', tag);
            formData.append('file', cropData, fileName); // Isso adiciona o arquivo
            formData.append('fileName', fileName); // Isso adiciona o nome do arquivo
            const response = await axios.post('https://apiventureirosonline.com:3000/imagensvisitas/imagensvisitas', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao enviar imagem:', error);
            
        }
    },

    async deleteImageAtividadesClube(chave, tag, cropData, fileName) {
        // consle.log("imagem", chave, cropData, fileName);
        try {
            // Criar um objeto FormData para enviar os dados da imagem
            let formData = new FormData();
            formData.append('chave', chave);
            formData.append('tag', tag);
            formData.append('file', cropData, fileName); // Isso adiciona o arquivo
            formData.append('fileName', fileName); // Isso adiciona o nome do arquivo
            const response = await axios.post('https://apiventureirosonline.com:3000/atividadesclube/imagensatividadesclube', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao enviar imagem:', error);
            
        }
    },

    async criaUsuarios(dados) {
        try {
            const response = await axios.post('https://apiventureirosonline.com:3000/createUsers', dados);
            // Retorne os dados da resposta
            return response.data;
        } catch (error) {
            // consle.log('Erro ao criaUsuarios:', error);
            
        }
    },

    async validation(token) {
        console.log("token", token);
        
        try {
            const response = await axios.get(`https://apiventureirosonline.com:3000/validation/${token}`);
            // Retorne os dados da resposta
            return response.data;
        } catch (error) {
            // consle.log('Erro ao validation:', error);
            
        }
    },

    async alteraSenha(dados) {
        try {
            const response = await axios.post('https://apiventureirosonline.com:3000/alterasenha', dados);
            // Retorne os dados da resposta
            return response.data;
        } catch (error) {
            // consle.log('Erro ao alteraSenha:', error);
            
        }
    },

    async editaUsuario(dados) {
        try {
            const response = await axios.post('https://apiventureirosonline.com:3000/editarUsuario', dados);
            // Retorne os dados da resposta
            return response.data;
        } catch (error) {
            console.error('Erro ao editaUsuario:', error);
            
        }
    },

    async deleteUsuario(dados) {
        try {
            const response = await axios.post('https://apiventureirosonline.com:3000/deleteUsuario', dados);
            // Retorne os dados da resposta
            return response.data;
        } catch (error) {
            console.error('Erro ao deleteUsuario:', error);
            
        }
    },

    async solicitaTrocaSenha(email){
        const user = {
            email: email
        }
        try {
            const response = await axios.post('https://apiventureirosonline.com:3000/solicitaTrocaSenha', user);

            console.log("api reorno solicitação senha",response);
            
            if (response.data.token) {
                return response.data;
            } else {
                 return response.data;
            }
        } catch (error) {
            console.error('Erro ao login:', error);
            
        }
    }

    // Adicione mais métodos conforme necessário
};

// Exporte o objeto contendo os métodos
module.exports = apiMethods;
