export default {
	state: {
		visitas: {}
	},
	getters: {
		"visitas": state => state.visitas
	},
	mutations: {
		setVisitas(state, payload) {
			state.usuario = payload
		}
	},
	actions: {
		atualizarEstadoVisitas({ commit }, payload) {
			commit('setVisitas', payload)
		}
	}
};