import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

// Importar o service worker
import "./registerServiceWorker";

// Importar os estilos do Bootstrap ou de outras bibliotecas que você esteja usando
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

// Importar FontAwesome
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';


library.add(fas, far, fab);
dom.watch();

const app = createApp(App);




// Configurar autenticação e roteamento
let user = store.getters.usuario.token;

if (user !== null) {
  router.push('/');
} else {
  // Usuário não autenticado
  app.config.globalProperties.$user = null;
  router.push('/login');
}

// Armazenar a versão atual do PWA
const currentAppVersion = '1.2.3';

if (localStorage.getItem('appVersion') !== currentAppVersion) {
  console.log("Atualizando o service worker");

  // Limpar o cache do service worker
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(registrations => {
      const unregisterPromises = registrations.map(registration => registration.unregister());

      Promise.all(unregisterPromises)
        .then(() => {
          console.log("Service workers desregistrados");
          return caches.keys();
        })
        .then(cacheNames => {
          return Promise.all(
            cacheNames.map(cacheName => {
              if (cacheName !== `app-static-${currentAppVersion}`) {
                console.log(`Deletando cache: ${cacheName}`);
                return caches.delete(cacheName);
              }
            })
          );
        })
        .then(() => {
          localStorage.setItem('appVersion', currentAppVersion);
          localStorage.setItem('versaoreloda', 'passsou pelo reload');
          
          //window.location.reload(); // Recarrega a página para registrar o novo service worker
          location.reload();
        })
        .catch(error => {
          console.error('Erro ao limpar o cache e desregistrar os service workers:', error);
        });
    });
  }
}

// Registrar o novo service worker
if ('serviceWorker'in navigator) {
  navigator.serviceWorker.register('/sw3.js')
    .then(registration => {
      console.log('Service worker registrado com sucesso:', registration);
    })
    .catch(error => {
      console.error('Falha ao registrar o service worker:', error);
    });
}



app.use(router);
app.use(store);
app.component("font-awesome-icon", FontAwesomeIcon);

app.mount('#app');
