import { createRouter, createWebHistory } from 'vue-router'
import store from '../store/index'
import HomeView from '../views/HomeView.vue'
import auth from '../validador/TokenValido'; // Substitua pelo caminho correto para o arquivo auth.js

const routes = [
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Login.vue')
    }
  },
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { requiresAuth: true } // Esta rota requer autenticação
  },
  {
    path: '/regionais-area',
    name: 'RegionaisArea',
    component: function () {
      return import('../views/RegionaisArea.vue')
    },
    meta: { requiresAuth: true }
  },
  {
    path: '/ideais-clube',
    name: 'IdeaisClube',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import('../views/IdeaisClube.vue')
    }
  },
  {
    path: '/vistas-clubes',
    name:'VisitasClubes',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import('../views/VisitasClubes')
    }
  },
  {
    path: '/outras-atividades',
    name:'OutrasAtividades',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import('../views/OutrasAtividades.vue')
    }
  },
  {
    path: '/lista-atividades',
    name:'ListaAtividades',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import('../views/ListaAtividades.vue')
    }
  },
  {
    path: '/lista-atividades-clube',
    name:'ListaAtividadesClube',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import('../views/ListaAtividadesClube.vue')
    }
  },
  {
    path: '/atividades-clube',
    name:'AtividadesClube',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import('../views/AtividadesClube.vue')
    }
  },
  {
    path: '/visitas-detalhes',
    name:'DetalhesClubeMda',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import('../views/VisitasDetalhes.vue')
    }
  },
  {
    path: '/atividades-detalhes',
    name:'DetalhesClube',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import('../views/AtividadesDetalhes.vue')
    }
  },
  {
    path: '/calendario',
    name: 'calendario',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Calendario.vue')
    }
  },
  {
    path: '/verification',
    name: 'verification',
    component: () => import(/* webpackChunkName: "gestaoUsuarios" */ '../views/Verification.vue')
  },
  {
    path: '/install-instructions',
    name: 'install-instructions',
    component: () => import('../views/InstallInstructions.vue')  // Substitua pelo caminho correto para o seu componente
  },
  {
    path: '/install-app',
    name: 'install-app',
    component: () => import('../views/InstaladoApp.vue')  // Substitua pelo caminho correto para o seu componente
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('../views/ResetPassword.vue')  // Substitua pelo caminho correto para o seu componente
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// Adicione um guarda de rota para verificar se o usuário está autenticadorouter.beforeEach(async(to, from, next) => {
  router.beforeEach(async(to, from, next) => {
   
    // consle.log("estore", store.getters.usuario.token);


    const token = store.getters.usuario.token;
    let autH = await   auth.verificarToken(token);
  if(autH === 'jwt expired' && to.name !== 'verification' && to.name !== 'install-instructions' && to.name !== 'reset-password' && to.name !== 'install-app' ){
    autH = false;
   // sessionStorage.removeItem('token');
   
  }
  if (autH === 'jwt malformed' && to.name !== 'verification' && to.name !== 'install-instructions' && to.name !== 'reset-password'  && to.name !== 'install-app') {
    autH = false;
   // sessionStorage.removeItem('token');
  }
    const isAuthenticated = token && autH
  
    if (to.name !== 'login' && !isAuthenticated && to.name !== 'verification' && to.name !== 'install-instructions' && to.name !== 'reset-password' && to.name !== 'install-app')  {
      next('/login'); // Redireciona para a página de login se o usuário não estiver autenticado e não estiver na página de login
    } 
    else {
      next(); // Continua a navegação normalmente
    }
  });

export default router
