<!-- Card.vue -->
<template>
  <div >
    <router-link :to="getLink()" class="card my-card  cor-card router-link-active" style="width: 6.8rem; height: 4.5rem;">
      <div >
        <p class=" title texto">{{ cardData.title }}</p>
        <p class="texto">{{ cardData.subtitle }}</p>
      </div>
    </router-link>
  </div>
  </template>
  
  <script>
  export default {
    props: {
      cardData: {
        type: Object,
        required: true
      }
    },
  methods: {
    getLink() {
      return this.cardData && this.cardData.link ? this.cardData.link : '/';
    }
  }
  }
  </script>
  
  <style scoped>
  .cor-card {
    background-color: #2062af
  }
  .texto {
  color:  #eff1f3;
}
  .my-card {
    margin-right: 6px; /* Adicione a margem direita desejada entre os cards */
  }
  .title {
    margin-top: 10px;
    font-size: 12px;
  }
  .card{
    font-size: 12px;
  }

  /* Remover sublinhado do link */
.router-link-exact-active,
.router-link-active {
  text-decoration: none;
}
  
  </style>