<template>
  <div>
    <router-view/>
  </div>
</template>

<script>
import "./assets/css/styles.css";

export default {
  data() {
    return {
      installPrompt: null,
      isStandalone: false
    };
  },
  
  async mounted() {
  const urlPath = window.location.pathname;

  if (urlPath.includes('verification')) {
    console.log("if", urlPath);

    // Espera a rota ser corretamente carregada
    await this.$nextTick();
    
    // Verificar se já estamos na rota verification para evitar loops
    
    return;
  } else if (urlPath.includes('login')) {

  this.checkPwaInstallation();

  // Inicializar evento para o prompt de instalação
  this.initInstallPrompt();

  // Rechecar a instalação quando o PWA ganhar foco ou mudar de visibilidade
  document.addEventListener('visibilitychange', this.checkPwaInstallation);
  window.addEventListener('focus', this.checkPwaInstallation);
  }
},

  methods: {
    initInstallPrompt() {
      window.addEventListener('beforeinstallprompt', event => {
        event.preventDefault();
        this.installPrompt = event;
      });
    },

    async checkPwaInstallation() {
      this.isStandalone = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true;

      // Verificar se a URL contém 'verification'
      
      // Verificar se o PWA está instalado
      if (!this.isStandalone) {
        console.log("PWA não instalado, redirecionando para as instruções de instalação.");

        // Verificar se já estamos na rota install-instructions para evitar loops
        if (this.$route.name !== 'install-instructions') {
          await this.$router.push({ name: 'install-instructions' });
        }
      } else {
        console.log("PWA está rodando em modo standalone.");

        // Verificar se já estamos na rota raiz para evitar loops
        if (this.$route.name !== 'home') { // 'home' é o nome da rota principal, ajuste conforme necessário
          await this.$router.push("/");
        }
      }
    }
  },

  beforeUnmount() {
    // Remover os eventos quando o componente for destruído
    document.removeEventListener('visibilitychange', this.checkPwaInstallation);
    window.removeEventListener('focus', this.checkPwaInstallation);
  }
};
</script>
