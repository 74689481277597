const axios = require('axios');



const auth = {
    async verificarToken(token) {
        try {
            const response = await axios.get('https://apiventureirosonline.com:3000/token', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            return response.data;
        } catch (error) {
            // consle.log('Erro ao obter verificarToken:', error.response.data.message);
           
            return error.response.data.message
             
        }
    }

};

export default auth;