<template>
  <div>
    <div v-if="isNotSelected" class="dropdown button">
      <select v-model="selectedFuncao" @change="grafico" class="dropdown-select">
       <option v-for="option in dropdownOptions" :key="option.value" :value="option.value">
          {{ option.label }} &nbsp;&nbsp;&nbsp;&nbsp;
        </option>
      </select>
    </div>
    <apexcharts type="line" height="350" :options="chartOptions" :series="chartOptions.series">
    </apexcharts>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VueApexCharts from 'vue3-apexcharts';
import apiMethods from '@/views/service/homeService';

export default {
  name: 'GraficoAtividades',

  components: {
    apexcharts: VueApexCharts,
  },
  computed: {
    ...mapGetters({
      usuario: "usuario"
    })
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'line',
          height: 350,
          toolbar: {
            show: false // Desabilita as opções do menu do gráfico
          }
        },
        series: [],
        xaxis: {
          categories: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
          labels: {
            style: {
              colors: Array(12).fill('#FFFFFF'), // cor branca para os meses
            }
          }
        },
        yaxis: {
          labels: {
            style: {
              colors: ['#FFFFFF'], // cor branca para o eixo Y
            }
          }
        },
        legend: {
          labels: {
            colors: '#FFFFFF', // cor branca para as legendas
          }
        }
      },
      dados: [],
      selectedFuncao: '',
      isNotSelected: null,
      dropdownOptions: [],
      dadosFiltrados: []
    };
  },

  async mounted() {
    await this.carregarDados();
    this.updateDropdownOptions();
    this.grafico();
  },

  watch: {
    selectedFuncao() {
      this.grafico();
    }
  },

  methods: {
    async carregarDados() {
      this.dados = await apiMethods.obterVisitas();

      console.log("get do banco grafico ", this.dados);
      
    },

    grafico() {
      const perfilFuncao = this.usuario.funcao
      //console.log("perfil grafico inicio tela",perfilFuncao );
      
      if (perfilFuncao === 'Diretor') {
        this.isNotSelected = false
      }
      if (perfilFuncao === 'Regional') {
        this.isNotSelected = true

       

      }
      if (perfilFuncao === 'Distrital') {
        this.isNotSelected = true
        
      }
      if (perfilFuncao === 'Coordenador') {
        this.isNotSelected = true
      }

      if (perfilFuncao === 'Secretária de área') {
        this.isNotSelected = true
      }

      

      if(this.usuario.funcao === 'Regional'){
        console.log("aqui", this.selectedFuncao);
        if (this.selectedFuncao === this.usuario.nome) {
          this.dadosFiltrados = this.dados.filter(visita => visita.nome === this.usuario.nome)
        }else if (this.selectedFuncao) {
          this.dadosFiltrados =  this.dados.filter(visita => visita.funcao === this.selectedFuncao)
        }else {
          this.dadosFiltrados = this.dados.filter(visita => visita.nome === this.usuario.nome)
        }
      }

      if(this.usuario.funcao === 'Distrital'){
        console.log("aqui", this.selectedFuncao);
        if (this.selectedFuncao === this.usuario.nome) {
          this.dadosFiltrados = this.dados.filter(visita => visita.nome === this.usuario.nome)
        }else if (this.selectedFuncao) {
          this.dadosFiltrados =  this.dados.filter(visita => visita.funcao === this.selectedFuncao)
        }else {
          this.dadosFiltrados = this.dados.filter(visita => visita.nome === this.usuario.nome)
        }
      }

      if(this.usuario.funcao === 'Coordenador'){
         this.dadosFiltrados = this.selectedFuncao      
        ? this.dados.filter(visita => visita.funcao === this.selectedFuncao)
        : this.dados
      }

      if(this.usuario.funcao === 'Secretária de área'){
         this.dadosFiltrados = this.selectedFuncao      
        ? this.dados.filter(visita => visita.funcao === this.selectedFuncao)
        : this.dados
      }
      // 
      //  this.dadosFiltrados = this.selectedFuncao      
      //   ? this.dados.filter(visita => visita.funcao === this.selectedFuncao)
      //   : this.dados.filter(visita => visita.nome === this.usuario.nome)



      const visitasPorPessoa = {};

      console.log("grafico dadosFiltrados", this.dadosFiltrados);
      

      this.dadosFiltrados.forEach(visita => {
        const mes = new Date(visita.dataVisita).getMonth(); // Obter o mês da visita (0 = Jan, 1 = Fev, ...)
        const nome = visita.nome;

        if (!visitasPorPessoa[nome]) {
          visitasPorPessoa[nome] = new Array(12).fill(0); // Inicializa um array com 12 zeros
        }

        visitasPorPessoa[nome][mes] += 1; // Incrementa a contagem de visitas no mês específico
      });

      // Atualizar a série de dados do gráfico
      this.chartOptions.series = Object.keys(visitasPorPessoa).map(nome => {
        return {
          name: nome,
          data: visitasPorPessoa[nome],
        };
      });
    },

    updateDropdownOptions() {
      const perfilFuncao = this.usuario.funcao;

      if (perfilFuncao === 'Regional') {
        this.dropdownOptions = [
          { value: `${this.usuario.nome}`, label: `${this.usuario.nome}`},
          { value: 'Distrital', label: 'Distrital' },
          { value: 'Diretor', label: 'Diretor' }
        ];
      } else if (perfilFuncao === 'Distrital') {
        this.dropdownOptions = [
          { value: `${this.usuario.nome}`, label: `${this.usuario.nome}`},
          { value: 'Diretor', label: 'Diretor' }
        ];
      } else if (perfilFuncao === 'Coordenador') {
        this.dropdownOptions = [
          {value: '', label: 'Mostrar Todos'},
          { value: 'Coordenador', label: 'Coordenador' },
          {value: 'Secretária de área', label: 'Secretária de área'},
          { value: 'Regional', label: 'Regional' },
          { value: 'Distrital', label: 'Distrital' },
          { value: 'Diretor', label: 'Diretor' }
        ];
      } 
      else if (perfilFuncao === 'Secretária de área') {
        this.dropdownOptions = [
          {value: '', label: 'Mostrar Todos'},
          { value: 'Coordenador', label: 'Coordenador' },
          {value: 'Secretária de área', label: 'Secretária de área'},
          { value: 'Regional', label: 'Regional' },
          { value: 'Distrital', label: 'Distrital' },
          { value: 'Diretor', label: 'Diretor' }
        ];
      }
    }
  }
};
</script>

<style scoped>
button {
  padding: 10px 15px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.dropdown {
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
}

.dropdown-select {
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  color: #333;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  width: 100%;
  box-sizing: border-box;
}

.dropdown-select:hover {
  border-color: #888;
}

.dropdown-select:focus {
  border-color: #555;
}

.dropdown::after {
  content: '▼';
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  font-size: 12px;
  color: #333;
}
</style>
