<template>
  <div class="home">
    <HelloWorld />
    <AcessoRapido />
    <div class="container-fluid">
      <div class="para-clube">
        <hr/>       
      </div>
      <div v-if="perfil !== 'Diretor'" class="card" >
          <div class="row card-body">
              <p class="card-text justify-text">
                <router-link to="/lista-atividades" class="router-link-active">
                  <i class="fa fa-list fa-1x" aria-hidden="true"></i>&nbsp;&nbsp;Lista de Atividades
                  </router-link>
              </p>
          </div>
      </div>
      <div v-if="perfil === 'Diretor'" class="card" >
          <div class="row card-body">
              <p class="card-text justify-text">
                <router-link to="/lista-atividades-clube" class="router-link-active">
                  <i class="fa fa-list fa-1x" aria-hidden="true"></i>&nbsp;&nbsp;Lista de Atividades Clube
                  </router-link>
              </p>
          </div>
      </div>
      <br>
      <div class="card" style="background-color:black;">
       <GraficoAtividades></GraficoAtividades>
      </div>
    </div>
  </div>
  <div>
    <p>versão app: {{ version }}</p>
  </div>
</template>

<script>
import {  mapGetters } from "vuex";
import HelloWorld from '@/components/HelloWorld.vue'
import AcessoRapido from '@/components/AcessoRapido.vue';
import GraficoAtividades from '@/components/grafico/AtividadesMes.vue'
// Importe os métodos do arquivo JavaScript que contém as requisições
import apiMethods from './service/homeService';


export default {
  name: 'HomeView',
  components: {
    HelloWorld,
    AcessoRapido,
    GraficoAtividades
  },
  computed: {
    ...mapGetters({
        usuario: "usuario"
    })
  },
  data() {
    return {
      isMobile: false,
      homeApp: "luis",
      users: null,
      perfil: null,
      avatar: null,
      version: null
    };
  },
  async created(){

    const currentAppVersion = '1.2.3';

    console.log("usuario ==> home", this.usuario);
    this.version = localStorage.getItem('appVersion');
  
if (currentAppVersion !== this.version ) {
  location.reload();
}

    try {
      const chave = this.usuario.chave;
       this.perfil = this.usuario.funcao;
      this.users = await apiMethods.getUsers(chave);
      // consle.log("users: ", this.users);
    } catch (error) {
      console.error('Erro ao obter usuários:', error);
    }
  },
  mounted() {
    window.addEventListener('resize', this.checkScreenSize);
    this.checkScreenSize();
  },
  methods: {
    checkScreenSize() {
      this.isMobile = window.innerWidth <= 768;
    }
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkScreenSize);
  }
}
</script>

<style scoped>
.cor-padrao {
  background-color: #020202;
}

.para-clube {
  display: flex;
  align-items: flex-start;
  margin-top: 7px;
  width: 200px;
  margin-right: 20px;
}

.card {
  background-color: #353030;
}


.card-text {
    display: flex;
    align-items: center;
    
}

p {
  color:  white;
  }

.justify-text {
    text-align: justify;
    text-justify: inter-word;
}

  /* Remover sublinhado do link */
 .router-link-exact-active,
.router-link-active {
  text-decoration: none;
  color: white;
}

</style>
