<template>
  <div>
    <div v-if="isVisible" class="modal" @animationend="handleAnimationEnd">
      <div class="modal-content background" :class="{ 'slide-out': isClosing }">
        <span @click="hide" class="close"><i class="fas fa-arrow-left"></i></span>
        <slot>
          <br>
          <br>
          <div class="form-container">
            <form @submit.prevent="salvar">
              <div class="card form-container">
                <div class="card-body">
                  <div class="form-group">
                    <label class="label" for="name">Nome</label>
                    <input type="text" v-model="usuario.nome" id="name" required />
                  </div>
                  <div class="form-group">
                    <label class="label" for="email">E-mail</label>
                    <input type="text" v-model="usuario.email" id="email" required />
                  </div>
                  <div class="form-group">
                    <label class="label" for="club">Clube</label>
                    <select v-model="usuario.clube" id="club" required>
                      <option value="" disabled>Selecione o Clube</option>
                      <option v-for="clube in clubs" :key="clube" :value="clube">{{ clube }}</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label class="label" for="funcao">funcao</label>
                    <select v-model="usuario.funcao" id="funcao" required>
                      <option value="" disabled>Selecione a função</option>
                      <option v-for="carg in roles" :key="carg" :value="carg">{{ carg }}</option>
                    </select>
                  </div>
                </div>
                <br>
                <button type="submit">Salvar</button>
              </div>
            </form>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from 'sweetalert2';
import apiMethods from '../views/service/homeService';
export default {
  name: 'ModalPerfil',
  components: {

  },
  data() {
    return {
      isVisible: false,
      isClosing: false,
      srcImage: null,
      cargo: '',
      roudChave: '',
      tag: 'avatar',
      usuario: {
        nome: "",
        email: "",
        clube: "",
        chave: "",
        funcao: ""
      },
      dados: {},
      clubs: [
        'Estrelas do rei',
        'Raio de luz',
        'Arara azul',
        'Pequena luz',
        'Rosa de Saron',
        'Estrelinhas do campo',
        'L2k2',
        'Itakids',
        'Soldadinhos de Cristo',
        'Raio de Sol',
        'Servos do Rei',
        'Israel Kids',
        'Pacikids',
        'Metrokids',
        'Guardiões Kids',
        'Conect Kids',
        'Estação Kids',
        'Estellaris',
        'Pinhalzinho',
        'Estrelinhas de Orion',
        'Harpa de Davi',
        'Planalto Kids',
        'Pacificadores kids',
        'Gralha Jr',
        'Vila Sorriso',
        'Andorinha',
        'Pioneiros do Fanny',
        'Joias do Amanhã',
        'Clube novo 5º Região',
        'Clube novo 16º Região',
        'Clube Caçadores de aventura',
        'MDA'
      ],
      roles: [
        'Coordenador',
        'Regional',
        'Distrital',
        'Secretária de área'
      ],
      avatarURL: ""
    };
  },
  async created() {
    this.roudChave = await apiMethods.roudChave();
    console.log("chave controle", this.roudChave);
    
  },
  methods: {
    async show() {
      this.isClosing = false;
      this.isVisible = true;
    },
    handleAnimationEnd() {
      if (this.isClosing) {
        this.isVisible = false;
        this.isClosing = false; // Reset isClosing
      }
    },
    hide() {
      this.isClosing = true;
    },
    fechar() {
      location.reload();
    },
    salvar() {
      this.loading = true

      let partes = this.roudChave[0].chave.split('-');
      let prefixo = partes[0];
      let numero = partes[1];

      // Converter a parte numérica para número, incrementar e depois formatar com 4 dígitos
      let numeroIncrementado = (parseInt(numero) + 1).toString().padStart(4, '0');

      // Construir a nova chave
      this.chave = `${prefixo}-${numeroIncrementado}`;


      this.dados = {
        nome: this.usuario.nome,
        email: this.usuario.email,
        clube: this.usuario.clube,
        funcao: this.usuario.funcao,
        chave: `${prefixo}-${numeroIncrementado}`,
        status: 'ativo',
      }
      apiMethods.criaUsuarios(this.dados).then((res) => {
        this.loading = false
        // consle.log("retono usuario", res);
        if (res === 'falha') {
          this.usuario.email = "";
          // consle.log("aqui");
          Swal.fire({
            icon: "error",
            title: "Verifique E-mail ",
            text: "E-mail já cadastrado no APP"
          });
        } else {
          Swal.fire({
            icon: "success",
            title: "Usuário cadastrado com sucesso",
            text: "Verifique o e-mail para confirmar o cadastro"
          });
          this.dados = {};
          this.roudChave = [];
          this.usuario.nome = "";
          this.usuario.email = "";
          this.usuario.funcao = "";
          this.usuario.chave = "";
          this.loading = false;
          this.isVisible = false;
          this.isClosing = false; // Reset isClosing
          setTimeout(() => {
            location.reload();
            }, 3000);
        
        } 


        this.$emit('salvar-created')
      }).catch((err) => {
        this.loading = false
         console.log(err)
      })
    }


  }
};
</script>
<style scoped>
.background {
  background-color: #800000;
}

.masthead {
  padding-top: calc(1rem + 4px);
}

.avatar-circle {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  position: relative;
}

.avatar-image {
  width: 100%;
  height: auto;
}

.edit-icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
}

.edit-icon:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #020202;
  padding: 20px;
  border: none;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  animation: slide-in 0.5s forwards;
}

@keyframes slide-in {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

.slide-out {
  animation: slide-out 0.5s forwards;
}

@keyframes slide-out {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(100%);
  }
}

.close {
  color: #fafafa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  position: fixed;
  z-index: 1;
  left: 0;
}

.form-container {
  max-width: 600px;
  padding: 20px;
  border: 1px solid #800000;
  border-radius: 5px;
  background-color: #800000;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: white;
  text-align: left;
}

select,
input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

button {
  padding: 10px 15px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.white-icon {
  color: #ffffff;
}



.base_spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.spinner {
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.path {
  stroke: #007bff;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
</style>